<!--系统菜单-->

<template>

</template>

<script>
export default {
  name: "systemMenu"
}
</script>

<style scoped>

</style>
