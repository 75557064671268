<!--充值管理-->
<template>
  <div class="main-container" style="background: #fff">
    <el-button
      type="primary"
      @click="add"
      size="mini"
      style="margin: 10px; float: right">新增充值</el-button>
    <el-form style="background: white;padding: 20px 20px 0" size="mini" :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="状态">
        <el-select v-model="formInline.region" placeholder="请选择状态">
          <el-option
            v-for="item in statusOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="是否确认">
        <el-select v-model="formInline.region01" placeholder="请选择是否确认">
          <el-option
            v-for="item in confirmOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="渠道">
        <el-select v-model="formInline.region02" placeholder="请选择渠道">
          <el-option
            v-for="item in channelOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="一级代理">
        <el-select v-model="formInline.region1" placeholder="请选择一级代理">
          <el-option
            v-for="item in primaryAgencyOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="二级代理">
        <el-select v-model="formInline.region3" placeholder="请选择二级代理">
          <el-option
            v-for="item in secondAgencyOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单号">
        <el-input v-model="formInline.syNumber" placeholder="请输入订单号"></el-input>
      </el-form-item>
      <el-form-item label="用户名称">
        <el-input v-model="formInline.username" placeholder="请输入用户名称"></el-input>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input v-model="formInline.account" placeholder="请输入手机号码"></el-input>
      </el-form-item>

      <el-form-item label="添加时间">

        <el-date-picker
          v-model="formInline.applyTime"
          type="date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          placeholder="添加时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getTopupList">搜索</el-button>
      </el-form-item>
    </el-form>

    <div style="background: white">
      <fieldset>
        <legend>数据小记</legend>
        <div class="row">
          <div class="col-12" style="font-size: 12px">
            累计充值：$70802.84，
            手续费：$10487.32，
            成功收款：189笔，
            充值成功人数：21人
          </div>
        </div>
      </fieldset>
    </div>
    <TableBody ref="tableBody">
      <template>
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="dataList"
          :header-cell-style="tableConfig.headerCellStyle"
          :size="tableConfig.size"
          :stripe="tableConfig.stripe"
          :border="false"
        >
          <el-table-column
            align="center"
            label="订单号"
            width="180"
            prop="syNumber"
          >

          </el-table-column>
          <el-table-column
            align="center"
            label="用户名"
            width="120"
            prop="comment"
          />
          <el-table-column
            align="center"
            label="手机号"
            width="120"
            prop="account"
          />
          <el-table-column
            align="center"
            min-width="120"
            label="累计充值/提现"
            prop="topupWithdrawalTotal"
          />

          <el-table-column
            align="center"
            min-width="130"
            label="一级代理/二级代理"
            prop="belong1AndBelong2"
          >

          </el-table-column>
          <el-table-column
            align="center"
            min-width="150"
            label="充值金额/手续费"
            prop="topUpBalance"
          >
            <template slot-scope="scope">
              <span>{{scope.row.topUpBalance}}/</span>
              <span>{{scope.row.serviceCharge}}</span>
            </template>

          </el-table-column>
          <el-table-column
            align="center"
            label="冻结金额"
            prop="freezeRecord"
          />
          <el-table-column
            align="center"
            label="支付方式"
            prop=""
          />
          <el-table-column
            align="center"
            label="类型"
            prop=""
          >
            <template slot-scope="scope">
              {{scope.row.topupType == '0' ? '用户充值' : '彩金'}}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            min-width="200"
            label="申请/处理时间"
            prop="applyTime"

          />
          <el-table-column
            align="center"
            min-width="140"

            label="用户确认/交易流水号"
            prop=""
          />
          <el-table-column
            align="center"
            label="通道下发"
            prop=""
            width="130px"
          />
          <el-table-column
            align="center"
            label="收款地址/充值地址"
            prop="payUsdt"
            min-width="220"

          />

          <el-table-column   align="center"
                             label="充值凭证">
            <template slot-scope="scope">

              <el-image
                :preview-src-list="[`https://amazonshoplink.icu/${scope.row.topupImage}`]"
                :src="`https://amazonshoplink.icu/${scope.row.topupImage}`"

              ></el-image>

            </template>
          </el-table-column>

          <el-table-column   align="center"
                             label="充值状态">
            <template slot-scope="scope">

              <span :class="{'on': scope.row.status == 1, 'off': scope.row.status == -1}">{{scope.row.status == 0 ? '待付款' : (scope.row.status == 1 ? '审核通过' : '已驳回')}}</span>
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            label="业务员"
            prop="manager"
            width="130px"
          />


          <el-table-column   align="center"
                             width="160"
                             fixed="right"
                             label="操作">
            <template slot-scope="scope">
              <el-button v-if="scope.row.status == 0" @click="topupVerifyt(scope.row, 1)" class="button btnGreen" size="mini">通过</el-button>
              <el-button v-if="scope.row.status == 0" @click="topupVerifyt(scope.row, -1)" class="button" size="mini" type="danger">驳回</el-button>


            </template>
          </el-table-column>
        </el-table>
      </template>



      <!-- 分页区域 -->
      <div class="serviceManage-page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.size"
          :page-sizes="[10, 20, 30, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </TableBody>

    <el-dialog
      title="新增充值"
      :visible.sync="dialogVisible"
      width="70%"
    >
      <el-form :label-position="'top'" class="form-style" ref="form" :model="formAdd" label-width="100px">

        <el-form-item label="充值金额">
          <el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="formAdd.topUpBalance" placeholder="请输入充值金额"></el-input>
        </el-form-item>

        <el-form-item label="手机号">
          <el-input v-model="formAdd.account" placeholder="请输入手机号"></el-input>
        </el-form-item>


      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取消</el-button>
    <el-button type="primary" @click="confirm">提交</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import {topupList, topupVerifyt} from '../../../api/index'
import {addTopUp} from '../../../api/tradApi'
import store from '../../../store/index'
import TableMixin, { PageModelMixin } from '@/mixins/TableMixin'
import {baseUrl} from '../../../api/url'
import {
  LikeSearchMixin,
  GetDataMixin,
  RefreshActionMixin
} from '@/mixins/ActionMixin'
import {addCustomer} from "@/api/roleApi";
export default {
  name: 'rechargeManagement',
  mixins: [
    TableMixin,
    LikeSearchMixin,
    GetDataMixin,
    PageModelMixin,
    RefreshActionMixin
  ],
  data() {
    return {
      baseUrl,
      dataList: [],
      dialogVisible: false,


      formInline: {
        applyTime: '',
        account: '',
        syNumber: '',
        username: ''
      },
      formAdd: {
        topUpBalance: '',
        account: ''
      },
      pagination: {
        page: 1,
        size: 10
      },
      total: 0,
      statusOption: [{
        label: '全部',
        value: ''
      },
        {
          label: '未支付',
          value: '1'
        },
        {
          label: '支付成功',
          value: '2'
        },
        {
          label: '支付失败',
          value: '3'
        }],
      primaryAgencyOption: [{
        label: '全部',
        value: ''
      }],
      confirmOption: [{
        label: '全部',
        value: ''
      },
        {
          label: '未确认',
          value: '1'
        },
        {
          label: '已确认',
          value: '2'
        }],
      channelOption: [{
        label: '全部',
        value: ''
      }],
      secondAgencyOption: [{
        label: '全部',
        value: ''
      }],

    }
  },
  created() {

  },
  mounted() {

    this.getTopupList()

  },
  methods: {
    add() {
      this.dialogVisible = true
      this.formAdd = {}
    },

    getTopupList() {
      topupList({...this.pagination, ...this.formInline}).then(res => {
        this.dataList = res.data.result
        this.total = res.data.total

      })
    },
    handleSizeChange(size) {
      this.formInline.size = size
      this.formInline.page = 1
      this.getTopupList()
    },
    handleCurrentChange(page) {
      this.formInline.page = page
      this.getTopupList()
    },
    // 充值审核
    topupVerifyt(row, status) {
      const data = {
        id: row.id,
        account: row.account,
        manager: window.localStorage.account,
        status
      }
      topupVerifyt(data).then(res => {
        if (res.data.status === 0) {
          this.getTopupList()
          this.$message({
            message: '操作成功',
            type: 'success'
          });
        }

      })
    },
    confirm() {
      addTopUp({...this.formAdd, manager: window.localStorage.account}).then(res => {
        if (res.data.status === 0) {
          this.dialogVisible = false
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTopupList()

        } else {
          this.$message({
            message: '此用户不存在',
            type: 'eror'
          })
        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>




.avatar-container {
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  vertical-align: middle;
  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .avatar-vip {
    border: 2px solid #cece1e;
  }
  .vip {
    position: absolute;
    top: 0;
    right: -9px;
    width: 15px;
    transform: rotate(60deg);
  }
}
.gender-container {
  .gender-icon {
    width: 20px;
  }
}

.btnGreen {
  background: #009688;
  color: white
}
.btnYellow {
  background: rgba(255, 184, 0);
  color: white;
}
</style>
