
<!--代理列表-->
<template>
  <div>
    <div v-if="notShow">无权限</div>
    <div class="main-container" v-else style="background: #fff">
      <el-button
        type="primary"
        @click="add"
        size="mini"
        style="margin: 10px; float: right">添加代理</el-button>
      <el-form
        :model="form"
        class="form-style"
        size="mini"
        :inline="true">
        <!--输入框-->
        <el-form-item label="用户名称">
          <el-input v-model="form.account" placeholder="用户名称"></el-input>
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input v-model="form.phone" placeholder="手机号码"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="getTableList">搜索</el-button>
        </el-form-item>
      </el-form>

      <TableBody ref="tableBody">
        <template>
          <el-table
            ref="table"
            v-loading="tableLoading"
            :data="tableData"
            :header-cell-style="tableConfig.headerCellStyle"
            :size="tableConfig.size"
            :stripe="tableConfig.stripe"
            :border="false">


            <el-table-column  label="ID" prop="id" align="center"></el-table-column>
            <el-table-column  label="绑定用户ID" prop="uid" align="center"></el-table-column>
            <el-table-column  label="级别" prop="level" align="center"></el-table-column>
            <el-table-column  label="用户名" prop="account" align="center"></el-table-column>
            <el-table-column  label="手机号" prop="phone" align="center"></el-table-column>
            <el-table-column  label="绑定域名" prop="domain" align="center"></el-table-column>
            <el-table-column  label="邀请码" prop="inviteCode" align="center"></el-table-column>
            <el-table-column  label="登录次数" prop="loginCount" align="center"></el-table-column>
            <el-table-column  label="使用状态" prop="status" align="center">
              <template slot-scope="scope">
                <span :class="{'on': scope.row.status == 0, 'off': scope.row.status == -1}">{{scope.row.status == 0 ? '使用中' : '已禁用'}}</span>
              </template>
            </el-table-column>
            <el-table-column min-width="150"  label="客户链接" prop="customerLink" align="center"></el-table-column>
            <el-table-column  label="添加时间" width="150" prop="createTime" align="center"></el-table-column>

            <!--操作-->
            <el-table-column label="操作" fixed="right" align="center" width="250">
              <template slot-scope="scope">

                <el-button @click="editPwd(scope.row)" class="button button-small" size="mini" type="primary">密码</el-button>
                <el-button @click="edit(scope.row)" class="button button-small btnGreen" size="mini" >编辑</el-button>
                <el-button @click="updateStatus(scope.row)" class="button button-small btnYellow" size="mini"  >{{scope.row.status == 0 ? '禁用' : '启用'}}</el-button>
                <el-button @click="deleteAgency(scope.row)" type="danger" class="button button-small" size="mini"  >删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>

        <!-- 分页区域 -->
        <div class="serviceManage-page">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.page"
            :page-size="pagination.size"
            :page-sizes="[10, 20, 30, 40]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>

      </TableBody>

      <!--新增/编辑代理-->
      <el-dialog
        :title="type === 'add' ? '新增代理' : '编辑代理'"
        :visible.sync="dialogVisible"
        width="50%"
      >
        <el-form :label-position="'left'" class="form-style" ref="form" :model="formAdd" label-width="100px">
          <el-form-item label="所属代理" v-if="type == 'add'">
            <el-select disabled v-model="formAdd.agentType" placeholder="请选择">
              <el-option
                v-for="item in option"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="登录用户账号">
            <el-input :disabled="type === 'edit'" v-model="formAdd.account" placeholder="请输入4位及以上字符登录用户账号"></el-input>
          </el-form-item>

          <el-form-item label="用户联系手机">
            <el-input v-model="formAdd.phone" placeholder="请输入用户联系手机"></el-input>
          </el-form-item>

          <el-form-item label="联系电子邮箱">
            <el-input v-model="formAdd.email" placeholder="请输入联系电子邮箱"></el-input>
          </el-form-item>
          <el-form-item label="绑定域名">
            <el-input v-model="formAdd.domain" placeholder="输入绑定域名  格式：xxx.com"></el-input>
          </el-form-item>
          <el-form-item label="客服链接">
            <el-input v-model="formAdd.customerLink" placeholder="请输入客服链接"></el-input>
          </el-form-item>

        </el-form>
        <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取消编辑</el-button>
    <el-button type="primary" @click="confirm">保存数据</el-button>
  </span>
      </el-dialog>


      <!--  密码-->

      <el-dialog
        title="设置密码"
        :visible.sync="dialogVisiblePwd"
        width="50%"
      >
        <el-form :label-position="'top'" class="form-style" ref="form" :model="formPwd" label-width="100px">

          <el-form-item label="登录用户账号">
            <el-input disabled v-model="formPwd.account" placeholder="请输入4位及以上字符登录用户账号"></el-input>
            <span class="el-form-tips">登录用户账号创建后，不允许再次修改</span>
          </el-form-item>

          <el-form-item label="新的登录密码">
            <el-input type="password" v-model="formPwd.password" placeholder="请输入新的登录密码"></el-input>
            <span class="el-form-tips">密码必须包含大小写字母、数字、符号的任意两者组合</span>
          </el-form-item>

          <el-form-item label="重复登录密码">
            <el-input type="password" v-model="formPwd.confirmPassword" placeholder="请输入重复登录密码"></el-input>
            <span class="el-form-tips">密码必须包含大小写字母、数字、符号的任意两者组合</span>

          </el-form-item>

        </el-form>
        <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisiblePwd = false">取消编辑</el-button>
    <el-button type="primary" @click="confirmPwd">保存数据</el-button>
  </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {agentList, agentInsert, agentUpdate, agentUpdatePwd, agentUpdateStatus, belongAgentList, deleteAgentList} from '../../../api/roleApi'
import TableMixin, {PageModelMixin} from '@/mixins/TableMixin'
import {GetDataMixin, DeleteItemsMixin} from '@/mixins/ActionMixin'
import md5 from 'crypto-js/md5';


export default {
  name: 'agencyList',
  mixins: [TableMixin, PageModelMixin, GetDataMixin, DeleteItemsMixin],
  data() {
    return {
      type: '',
      tableLoading: false,
      tableData: [],
      option: [],
      form: {
        account: '',
        phone: ''
      },
      pagination: {
        page: 1,
        size: 20
      },
      total: 0,
      dialogVisible: false,
      dialogVisiblePwd: false,
      formAdd: {
        agentType: '',
        phone: '',
        customerLink: '',
        account: '',
        email: '',
        domain: ''
      },
      formPwd: {
        id: '',
        password: '',
        confirmPassword: ''
      },
      notShow: false

    }
  },
  mounted() {
    this.notShow = window.localStorage.isAdmin != '1'
    this.formAdd.agentType = this.$route.query.account
    this.getTableList()
    this.belongAgentList()
  },
  methods: {
    belongAgentList() {
      belongAgentList().then(res => {
        this.option = res.data.result
      })
    },
    setmd5(val) {
      if (val) {
        return md5(val).toString()
      } else {
        return ''
      }
    },
    getTableList() {
      agentList({...this.pagination, ...this.form, agentType: this.$route.query.account}).then(res => {
        this.tableData = res.data.result
        this.total = res.data.total
      })
    },
    handleSizeChange(size) {
      this.pagination.size = size
      this.pagination.page = 1
      this.getTableList()
    },
    handleCurrentChange(page) {
      this.pagination.page = page
      this.getTableList()
    },
    deleteFunc(row) {
      deleteAgentList({id: row.id}).then(res => {
        if (res.data.status === 0) {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTableList()
        }
      })
    },
    deleteAgency(row) {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteFunc(row)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    add() {
      this.type = 'add'
      this.dialogVisible = true
      this.formAdd.agentType = this.$route.query.account
      this.formAdd.account = ''
      this.formAdd.phone = ''
      this.formAdd.email = ''
      this.formAdd.domain = ''
      this.formAdd.customerLink = ''
    },
    edit(row) {
      this.type = 'edit'
      this.formAdd = JSON.parse(JSON.stringify(row))
      this.dialogVisible = true
    },
    addSubmit() {
      agentInsert({...this.formAdd}).then(res => {
        if (res.data.status == 0) {
          this.dialogVisible = false
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTableList()

        }
      })
    },
    editSubmit() {
      agentUpdate({...this.formAdd}).then(res => {
        if (res.data.status == 0) {
          this.dialogVisible = false
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTableList()

        }
      })
    },
    confirm() {
      if (this.type === 'add') {
        this.addSubmit()
      } else {
        this.editSubmit()
      }
    },
    // 编辑密码
    editPwd(row) {
      this.dialogVisiblePwd = true
      this.formPwd.password = ''
      this.formPwd.confirmPassword = ''
      this.formPwd.id = row.id
      this.formPwd.account = row.account
    },
    // 确认修改Miami
    confirmPwd() {
      if (!this.formPwd.password && !this.formPwd.confirmPassword) {
        this.$message({
          message: '请输入新的登录密码和重复登录密码',
          type: 'error'
        })
      } else {
        if (!this.formPwd.password) {
          this.$message({
            message: '请输入新的登录密码',
            type: 'error'
          })
        } else if (!this.formPwd.confirmPassword) {
          this.$message({
            message: '请输入重复登录密码',
            type: 'error'
          })
        } else if ((this.formPwd.password && this.formPwd.password.length < 6) || (this.formPwd.confirmPassword && this.formPwd.confirmPassword.length < 6)) {
          this.$message({
            message: '密码需要六位及以上',
            type: 'error'
          })

        } else if (this.formPwd.confirmPassword !== this.formPwd.password) {
          this.$message({
            message: '重复登录密码和新的登录密码保持一致',
            type: 'error'
          })
        } else {
          const data = {
            password: this.setmd5(this.formPwd.password),
            confirmPassword: this.setmd5(this.formPwd.confirmPassword),
            id: this.formPwd.id
          }
          agentUpdatePwd(data).then(res => {
            if (res.data.status == 0) {
              this.dialogVisiblePwd = false
              this.$message({
                message: '操作成功',
                type: 'success'
              })
              this.getTableList()

            }
          })
        }
      }
    },
    // 修改状态
    updateStatus(row) {
      const data = {
        id: row.id,
        status: row.status === 0 ? -1 : 0
      }
      agentUpdateStatus(data).then(res => {
        if (res.data.status === 0) {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getTableList()
        }
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.button-small {
  padding: 6px 12px;
}
.btnGreen {
  background: #009688;
  color: white
}
.btnYellow {
  background: rgba(255, 184, 0);
  color: white;
}


</style>

