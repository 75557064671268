
<!--会员入职单-->
<template>
  <div class="main-container">
    <el-form
      :model="form"
      class="form-style"
      size="mini"
      :inline="true">



      <el-form-item label="用户名称">
        <el-input v-model="form.value" placeholder="用户名称"></el-input>
      </el-form-item>

      <el-form-item label="选择日期">
        <el-date-picker
          v-model="form.value"
          type="date"
          placeholder="选择日期">
        </el-date-picker>
      </el-form-item>


      <el-form-item>
        <el-button type="primary" @click="getTableList">生成报表</el-button>
      </el-form-item>
    </el-form>


  </div>
</template>

<script>
import {} from '../../../api/index'
import TableMixin, {PageModelMixin} from '@/mixins/TableMixin'
import {GetDataMixin, DeleteItemsMixin} from '@/mixins/ActionMixin'

export default {
  name: 'memberEnterForm',
  mixins: [TableMixin, PageModelMixin, GetDataMixin, DeleteItemsMixin],
  data() {
    return {
      tableLoading: false,
      tableData: [{}],
      form: {
        value: ''
      },
      pagination: {
        page: 1,
        size: 10,
        total: ''
      }

    }
  },
  mounted() {
    this.getTableList()
  },
  methods: {
    getTableList() {},
    handleSizeChange(size) {
      this.pagination.size = size
      this.pagination.page = 1
    },
    handleCurrentChange(page) {
      this.pagination.page = page
    }
  }

}
</script>

<style lang="scss" scoped>
</style>

