<!--会员等级-->


<template>
  <div class="main-container">

    <TableBody ref="tableBody">
      <template>
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="dataList"
          :header-cell-style="tableConfig.headerCellStyle"
          :size="tableConfig.size"
          :stripe="tableConfig.stripe"
          :border="false"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            label="id"
            type="index"
            width="50">
          </el-table-column>
          <el-table-column
            align="center"
            label="显示"
            width="80"
            prop=" "
          >
          </el-table-column>

          <el-table-column
            align="center"
            label="名称"
            prop=" "
            width="130px"
          />
          <el-table-column
            align="center"
            label="会员价格"
            prop=" "
            width="130px"
          />
          <el-table-column
            align="center"
            label="人数"
            prop=" "
            width="130px"
          />
          <el-table-column
            align="center"
            label="今日抢单人数"
            prop=" "
            width="130px"
          />
          <el-table-column
            align="center"
            label="3日抢单人数"
            prop=" "
            width="130px"
          />



          <el-table-column
            align="center"
            label="7日抢单人数"
            prop=" "
            width="130px"
          />
          <el-table-column
            align="center"
            label="30日抢单人数"
            prop=" "
            width="130px"
          />
          <!--新加-->
          <el-table-column
            align="center"
            label="利息宝金额"
            prop=""
            width="130px"
          />

          <el-table-column
            align="center"
            label="佣金比例"
            prop=" "
            width="130px"
          />



          <el-table-column
            align="center"
            label="下级佣金比例"
            prop=" "
            width="130px"
          />
          <el-table-column
            align="center"
            label="最小余额"
            prop=" "
            width="130px"
          />
          <!--新加-->
          <el-table-column
            align="center"
            label="接单次数"
            prop=""
            width="130px"
          />
          <!--新加-->
          <el-table-column
            align="center"
            label="提现次数"
            prop=""
            width="130px"
          />
          <el-table-column
            align="center"
            label="提现最小金额"
            prop=" "
            width="130px"
          />
          <el-table-column
            align="center"
            label="提现最大金额"
            prop=" "
            width="130px"
          />
          <el-table-column
            align="center"
            width="200"
            label="操作"
            fixed="right"

          >
            <template slot-scope="scope">

              <el-button class="button" @click="edit(scope.row)" type="primary" size="mini"  >编辑</el-button>
              <el-button class="button" size="mini" type="primary">批量加入连单</el-button>


            </template>
          </el-table-column>
        </el-table>



      </template>


      <!-- 分页区域 -->
      <div class="serviceManage-page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="formInline.page"
          :page-size="formInline.size"
          :page-sizes="[10, 20, 30, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>


    </TableBody>
  </div>
</template>

<script>
import {memberList, memberEdit} from '../../../api/index'
import TableMixin, { PageModelMixin } from '@/mixins/TableMixin'
import { GetDataMixin, DeleteItemsMixin } from '@/mixins/ActionMixin'
export default {
  name: 'memberLevel',
  mixins: [TableMixin, PageModelMixin, GetDataMixin, DeleteItemsMixin],
  data() {
    return {
      dialogVisible: false,
      form: {},
      formInline: {
        page: 1,
        size: 10
      },
      total: '',
      primaryAgencyOption: [{
        label: '全部',
        value: ''
      }],
      salesmanOption: [{
        label: '全部',
        value: ''
      }],
      groupOption: [{
        label: '全部',
        value: ''
      }],
      sortModelOption: [{
        label: '充值金额倒序',
        value: ''
      },
        {
          label: '充值次序倒序',
          value: ''
        },
        {
          label: '提现金额倒序',
          value: ''
        },
        {
          label: '提现次数倒序',
          value: ''
        }],
    }
  },
  mounted() {
    this.getMemberList()

  },
  methods: {
    edit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogVisible = true
    },

    confirmEdit() {

      const data = {
        account: this.form.account.substring(2),
        balance: Number(this.form.balance),
        status: this.form.status,
        comment: this.form.comment
      }
      memberEdit(data).then(res => {
        if (res.data.status == 0) {
          this.dialogVisible = false
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getMemberList()
        }
      })
    },

    // 获取会员列表
    getMemberList() {
      memberList({page: this.formInline.page, size: this.formInline.size}).then(res => {
        this.dataList = res.data.result
        this.total =res.data.total

      })
    },
    handleSizeChange(size) {
      this.formInline.size = size
      this.formInline.page = 1
      this.getMemberList()
    },
    handleCurrentChange(page) {
      this.formInline.page = page
      this.getMemberList()
    }
  }

}
</script>

<style lang="scss" scoped>


.serviceManage-page {
  height: 48.5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding-left: 24px;
}


.avatar-container {
  position: relative;
  width: 30px;
  margin: 0 auto;
  vertical-align: middle;
  .avatar {
    width: 100%;
    border-radius: 50%;
  }
  .avatar-vip {
    border: 2px solid #cece1e;
  }
  .vip {
    position: absolute;
    top: 0;
    right: -9px;
    width: 15px;
    transform: rotate(60deg);
  }
}
.gender-container {
  .gender-icon {
    width: 20px;
  }
}
</style>

