<template>
  <div class="main-container">
 <el-form style="background: white;padding: 20px 20px 0" size="mini" :inline="true" :model="formInline" class="demo-form-inline">
    <el-form-item label="状态">
    <el-select v-model="formInline.region" placeholder="请选择状态">
      <el-option
      v-for="item in statusOption"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
    </el-select>
  </el-form-item>

      <el-form-item label="是否确认">
    <el-select v-model="formInline.region01" placeholder="请选择是否确认">
      <el-option
      v-for="item in confirmOption"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
    </el-select>
  </el-form-item>

  <el-form-item label="渠道">
    <el-select v-model="formInline.region02" placeholder="请选择渠道">
      <el-option
      v-for="item in channelOption"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
    </el-select>
  </el-form-item>
  <el-form-item label="一级代理">
    <el-select v-model="formInline.region1" placeholder="请选择一级代理">
      <el-option
      v-for="item in primaryAgencyOption"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
    </el-select>
  </el-form-item>

    <el-form-item label="二级代理">
    <el-select v-model="formInline.region3" placeholder="请选择二级代理">
      <el-option
      v-for="item in secondAgencyOption"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
    </el-select>
  </el-form-item>
    <el-form-item label="订单号">
    <el-input v-model="formInline.region7" placeholder="请输入订单号"></el-input>
  </el-form-item>
   <el-form-item label="用户名称">
    <el-input v-model="formInline.region5" placeholder="请输入用户名称"></el-input>
  </el-form-item>
  <el-form-item label="手机号码">
    <el-input v-model="formInline.region6" placeholder="请输入手机号码"></el-input>
  </el-form-item>

  <el-form-item label="添加时间">
    <el-input v-model="formInline.region8" placeholder="请输入添加时间"></el-input>
  </el-form-item>
  <el-form-item>
    <el-button type="primary" @click="onSubmit">搜索</el-button>
  </el-form-item>
</el-form>

<div style="background: white">
              <fieldset>
                        <legend>数据小记</legend>
                        <div class="row">
                            <div class="col-12" style="font-size: 12px">
                                第三方累计充值：$70802.84，
                                第三方手续费：$10487.32，
                                第三方成功收款：189笔，
                                第三方充值成功人数：21人
                            </div>
                        </div>
                    </fieldset>
                    </div>
    <TableBody ref="tableBody">
      <template>
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="dataList"
          :header-cell-style="tableConfig.headerCellStyle"
          :size="tableConfig.size"
          :stripe="tableConfig.stripe"
          :border="false"
        >
          <el-table-column
            align="center"
            label="订单号"
            width="80"
            prop="syNumber"
          >
          
          </el-table-column>
          <el-table-column
            align="center"
            label="用户"
            prop="username"
          />

          <el-table-column
            align="center"
            label="一级代理/业务员"
            prop=""
          >
           
          </el-table-column>
          <el-table-column
            align="center"
            label="充值金额/手续费"
            prop="topUpBalance"
          >
          <template slot-scope="scope">
            <p>{{scope.row.topUpBalance}}</p>
            <p>{{scope.row.serviceCharge}}</p>
          </template>
          
          </el-table-column>
          <el-table-column
            align="center"
            label="支付方式"
            prop=""
          />
          <el-table-column
            align="center"
            label="类型"
            prop=""
            width="160px"
          />
          <el-table-column
            align="center"
            label="申请/处理时间"
            prop="applyTime"
            width="130px"
          />
          <el-table-column
            align="center"
            label="收款地址/充值地址"
            prop="payUsdt"
            width="130px"
          />

          <el-table-column   align="center"
            label="充值状态">
            <template slot-scope="scope">
                <el-button v-if="scope.row.status == 1" size="mini" :style="{background: '#009688', color: 'white'}">审核通过</el-button>
                <span v-if="scope.row.status == 0">未付款</span>
            </template>
            </el-table-column>
 

           <el-table-column   align="center"
            label="操作">
            <template slot-scope="scope">
                <el-button @click="topupVerifyt(scope.row)" v-if="scope.row.status == 0" size="mini" :style="{background: '#009688', color: 'white'}">审核通过</el-button>
            </template>
            </el-table-column>
        </el-table>
      </template>


      
          <!-- 分页区域 -->
    <div class="serviceManage-page">
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="formInline.page"
      :page-size="formInline.size"
      :page-sizes="[10, 20, 30, 40]"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      >
      </el-pagination>
    </div>
    </TableBody>

  </div>
</template>

<script>
import {topupList, topupVerifyt} from '../../api/index'
import TableMixin, { PageModelMixin } from '@/mixins/TableMixin'
import {
  LikeSearchMixin,
  GetDataMixin,
  RefreshActionMixin
} from '@/mixins/ActionMixin'
export default {
  name: 'TableWithSearch',
  mixins: [
    TableMixin,
    LikeSearchMixin,
    GetDataMixin,
    PageModelMixin,
    RefreshActionMixin
  ],
  data() {
    return {
      formInline: {
          page: 1,
        size: 10
        },
        total: 0,
        statusOption: [{
          label: '全部',
          value: ''
        },
        {
          label: '未支付',
          value: '1'
        },
        {
          label: '支付成功',
          value: '2'
        },
        {
          label: '支付失败',
          value: '3'
        }],
        primaryAgencyOption: [{
          label: '全部',
          value: ''
        }],
        confirmOption: [{
          label: '全部',
          value: ''
        },
        {
          label: '未确认',
          value: '1'
        },
        {
          label: '已确认',
          value: '2'
        }],
        channelOption: [{
          label: '全部',
          value: ''
        }],
        secondAgencyOption: [{
          label: '全部',
          value: ''
        }],
        
    }
  },
  created() {

  },
  mounted() {
    this.getTopupList()

  },
  methods: {
     // 获取会员列表
    getTopupList() {
          topupList({page: this.formInline.page, size: this.formInline.size}).then(res => {
            this.dataList = res.data.result
            this.total =res.data.total
            
          })
    },
    handleSizeChange(size) {
      this.formInline.size = size
      this.formInline.page = 1
      this.getTopupList()
    },
    handleCurrentChange(page) {
      this.formInline.page = page
      this.getTopupList()
    },
    // 充值审核
    topupVerifyt(row) {
      const data = {
        id: row.id,
        account: row.account,
        status: 1
      }
      topupVerifyt(data).then(res => {
        if (res.data.status == 0) {
               this.getTopupList()
           this.$message({
          message: '操作成功',
          type: 'success'
        });
        }
   
      })
    }
 
  }
}
</script>

<style lang="scss" scoped>



 
.avatar-container {
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  vertical-align: middle;
  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .avatar-vip {
    border: 2px solid #cece1e;
  }
  .vip {
    position: absolute;
    top: 0;
    right: -9px;
    width: 15px;
    transform: rotate(60deg);
  }
}
.gender-container {
  .gender-icon {
    width: 20px;
  }
}
</style>
