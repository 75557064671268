
<!---->
<template>
  <div class="main-container">
    <el-button class="button" size="mini" type="primary">添加选项</el-button>
    <el-form
      :model="form"
      class="form-style"
      size="mini"
      :inline="true">



      <!--选择日期-->
      <el-form-item label="发起时间">
        <el-date-picker
          v-model="form.value"
          type="date"
          placeholder="请选择发起时间">
        </el-date-picker>
      </el-form-item>


      <el-form-item>
        <el-button type="primary" @click="getTableList">搜索</el-button>
      </el-form-item>
    </el-form>

    <TableBody ref="tableBody">
      <template>
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="tableData"
          :header-cell-style="tableConfig.headerCellStyle"
          :size="tableConfig.size"
          :stripe="tableConfig.stripe"
          :border="false">
          <!--选择-->
          <el-table-column type="selection" width="45"/>
          <!--排序-->
          <el-table-column label="编号" type="index" width="50"></el-table-column>

          <el-table-column  label="期限" prop="" align="center"></el-table-column>
          <el-table-column  label="利率" prop="" align="center"></el-table-column>
          <el-table-column  label="手续费" prop="" align="center"></el-table-column>
          <el-table-column  label="最低限制金额" prop="" align="center"></el-table-column>
          <el-table-column  label="最高限制金额" prop="" align="center"></el-table-column>
          <el-table-column  label="状态" prop="" align="center"></el-table-column>
          <el-table-column  label="投资人数" prop="" align="center"></el-table-column>
          <el-table-column  label="投资次数" prop="" align="center"></el-table-column>
          <el-table-column  label="投资总金额" prop="" align="center"></el-table-column>
          <el-table-column  label="已完成金额" prop="" align="center"></el-table-column>
          <el-table-column  label="未完成金额" prop="" align="center"></el-table-column>
          <el-table-column  label="提交时间" prop="" align="center"></el-table-column>



          <!--操作-->
          <el-table-column label="操作" fixed="right" align="center" width="300">
            <template slot-scope="scope">
              <el-button class="button" size="mini" type="primary">启用</el-button>
              <el-button class="button" size="mini" type="primary">编辑</el-button>
              <el-button class="button" size="mini" type="primary">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <!-- 分页区域 -->
      <div class="serviceManage-page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.size"
          :page-sizes="[10, 20, 30, 40]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
      </div>

    </TableBody>
  </div>
</template>

<script>
import {} from '../../../api/index'
import TableMixin, {PageModelMixin} from '@/mixins/TableMixin'
import {GetDataMixin, DeleteItemsMixin} from '@/mixins/ActionMixin'

export default {
  name: 'interestOption',
  mixins: [TableMixin, PageModelMixin, GetDataMixin, DeleteItemsMixin],
  data() {
    return {
      tableLoading: false,
      tableData: [{}],
      form: {
        value: ''
      },
      pagination: {
        page: 1,
        size: 10,
        total: ''
      }

    }
  },
  mounted() {
    this.getTableList()
  },
  methods: {
    getTableList() {},
    handleSizeChange(size) {
      this.pagination.size = size
      this.pagination.page = 1
    },
    handleCurrentChange(page) {
      this.pagination.page = page
    }
  }

}
</script>

<style lang="scss" scoped>
</style>

