
<!--数据报表-->
<template>

</template>

<script>
export default {
  name: "data"
}
</script>

<style scoped>

</style>
