<template>
<div>
  <div v-if="notShow">无权限</div>
  <div v-else></div>
</div>

</template>

<script>
export default {
  name: "customerCode",
  data() {
    return {
      notShow: false
    }
  },
  mounted() {
    this.notShow = window.localStorage.isAdmin != '1'
  }
}
</script>

<style scoped>

</style>
