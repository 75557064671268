<template>
  <div class="main-container">
  <el-form :model="formInline" style="background: white;padding: 20px 20px 0" size="mini" :inline="true" class="demo-form-inline">
     <el-form-item label="用户ID">
    <el-input v-model="formInline.region1" placeholder="请输入用户ID"></el-input>
  </el-form-item>
  <el-form-item label="手机号码">
    <el-input v-model="formInline.region2" placeholder="请输入手机号码"></el-input>
  </el-form-item>
  <el-form-item label="注册时间">
    <el-input v-model="formInline.region3" placeholder="请输入注册时间"></el-input>
  </el-form-item>
  <el-form-item label="注册IP">
    <el-input v-model="formInline.region4" placeholder="请输入注册IP"></el-input>
  </el-form-item>
    <el-form-item>
    <el-button type="primary" @click="getGrabList">搜索</el-button>
  </el-form-item>
  </el-form>
    <TableBody ref="tableBody">
      <template>
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="dataList"
          :header-cell-style="tableConfig.headerCellStyle"
          :size="tableConfig.size"
          :stripe="tableConfig.stripe"
          :border="tableConfig.border"
          row-key="id"
        
        >
          <el-table-column
            align="center"
            label="用户ID"
            fixed="left"
            width="80"
            prop="uid"
          >

          </el-table-column>
          <el-table-column
            align="center"
            label="推荐人"
            prop="inviatorId"
          />
          <el-table-column
            align="center"
            label="手机号"
            prop="phoneNumber"
          />
          <el-table-column
            align="center"
            label="备注"
            prop="comment"
          />
          <el-table-column
            align="center"
            label="创建时间"
            prop="createTime"
          />
          <el-table-column
            align="center"
            label="抢单开关"
          >
            <template slot-scope="scope">
              <el-switch
              @change="changeSwitch(scope.row.uid, scope.row.grabSwitch)"
                v-model="scope.row.grabSwitch"
                :active-value="1"
                :inactive-value="0"
              />
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="已完成/总单数"
            prop="finishAndTotal"
            width="160px"
          />
          <el-table-column
            align="center"
            label="注册IP"
            prop="registerIp"
            width="160px"
          />
          <el-table-column
            align="center"
            label="地区"
            prop="area"
            width="160px"
          />
          
          <el-table-column
            align="center"
            label="账户余额"
            prop="balance"
            width="160px"
          />
          <el-table-column
            align="center"
            label="佣金"
            prop="commission"
            width="160px"
          />
          <el-table-column
            align="center"
            label="冻结金额"
            prop="freezeAmount"
            width="160px"
          />
          <el-table-column
            align="center"
            fixed="right"
            label="操作"
          >
            <template slot-scope="scope">
             <el-button size="mini" type="danger" @click="dispatchDialog(scope.row.uid, scope.row.phoneNumber)">派单</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>

            <!-- 分页区域 -->
    <div class="serviceManage-page">
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="formInline.page"
      :page-size="formInline.size"
      :page-sizes="[10, 20, 30, 40]"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      >
      </el-pagination>
    </div>
    </TableBody>
<!-- 派单 -->
    <el-dialog
  title="派单"
  :visible.sync="dialogVisible"
  width="80%"
  :before-close="handleClose">

    <el-form :model="formData" style="background: white;padding: 20px 20px 0" size="mini" :inline="true" class="demo-form-inline">
     <el-form-item label="任务编号">
    <el-input v-model="formData.taskId" placeholder="请输入任务编号"></el-input>
  </el-form-item>
  <el-form-item label="倍数">
    <el-input v-model="formData.power" placeholder="请输入倍数"></el-input>
  </el-form-item>
  <el-form-item label="最大值">
    <el-input v-model="formData.maxNum" placeholder="请输入最大值"></el-input>
  </el-form-item>
  <el-form-item label="最小值">
    <el-input v-model="formData.minNum" placeholder="请输入最小值"></el-input>
  </el-form-item>
    <el-form-item>
    <el-button type="danger" size="mini" @click="dispatchOrder()">派单</el-button>
  </el-form-item>
  </el-form>


      <TableBody ref="tableBody">
      <template>
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="dispatchData"
          :header-cell-style="tableConfig.headerCellStyle"
          :size="tableConfig.size"
          :stripe="tableConfig.stripe"
          :border="tableConfig.border"
          row-key="id"
        
        >
          <el-table-column
            align="center"
            label="时间"
            width="80"
            prop="createTime"
          >

          </el-table-column>
          <el-table-column
            align="center"
            label="任务编号"
            prop="taskId"
          />
          <el-table-column
            align="center"
            label="倍数"
            prop="power"
          />
          <el-table-column
            align="center"
            label="最大值"
            prop="maxNum"
          />
          <el-table-column
            align="center"
            label="最小值"
            prop="minNum"
          />

          <el-table-column
            align="center"
            label="状态"
            prop="minNum"
          >
          <template slot-scope="scope">
            <el-button size="mini" v-if="scope.row.status === 1"   :style="{background: '#009688', color: 'white'}" >已完成</el-button>
            <el-button size="mini" v-if="scope.row.status === 0"  :style="{background: '#f56c6c', color: 'white'}" >未完成</el-button>
            <!-- <div  :class="{'colorRed': scope.row.status == 0, 'colorGreen': scope.row.status == 1}"></div> -->
          </template>
          </el-table-column>
        </el-table>
      </template>

    </TableBody>
 
</el-dialog>
  </div>
</template>

<script>
import TableMixin from '@/mixins/TableMixin'
import {grabList, grabSwitch, dispatchList, dispatchOrder} from '../../api/index'
// import {
//   AddItemMixin,
//   DeleteItemsMixin,
//   GetDataMixin,
//   UpdateItemMixin
// } from '@/mixins/ActionMixin'
export default {
  name: 'Department',
 
  mixins: [
    TableMixin,
    // GetDataMixin,
    // AddItemMixin,
    // DeleteItemsMixin,
    // UpdateItemMixin
  ],
  data() {
    return {
      formInline: {
         page: 1,
        size: 1
        },
        total: '',
        dialogVisible: false,
        formData: {
          taskId: '',
          power: '',
          minNum: '',
          maxNum: ''
        },
        dispatchUid: '',
        dispatchPhoneNumber: '',
        dispatchData: []
    }
  },
  computed: {
  },
  mounted() {
 this.getGrabList()
  },
  methods: {
        // 获取抢单列表
    getGrabList() {
          grabList({page: this.formInline.page, size: this.formInline.size}).then(res => {
            this.dataList = res.data.result
            this.total =res.data.total
            
          })
    },

        handleSizeChange(size) {
      this.formInline.size = size
      this.formInline.page = 1
      this.getGrabList()
    },
    handleCurrentChange(page) {
      this.formInline.page = page
      this.getGrabList()
    },
    // 抢单开关
    changeSwitch(uid, val) {
      console.log(val)
      grabSwitch({uid, grabSwitch: val}).then(res => {
        if (res.data.status == 0) {
          this.getGrabList()
           this.$message({
          message: '操作成功',
          type: 'success'
        });
        }
      })
    },
    // 派单
    dispatchDialog(uid, phoneNumber) {
      this.dispatchUid = uid
      this.dispatchPhoneNumber = phoneNumber
      this.dialogVisible = true
      this.dispatchList(uid)
    },
    // 查询已派单
    dispatchList(uid) {
      dispatchList({uid}).then(res => {
        this.dispatchData = res.data.result
      })
    },
    // 派单
    dispatchOrder() {
      const data = {
        uid: this.dispatchUid,
        account: this.dispatchPhoneNumber,
        ...this.formData
      }
      dispatchOrder(data).then(res => {
        if (res.data.status == 0) {
           this.$message({
          message: '操作成功',
          type: 'success'
        });
        this.dispatchList(this.dispatchUid)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.table-container {
  bottom: 8px;
}
.colorRed {
  background: #f56c6c;
  color: white;
}
.colorGreen {
  background: rgb(0, 150, 136);
  color: white;
}
</style>
