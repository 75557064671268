/**
 * 交易相关接口
 * */
import fetch from '../utils/fetch.js'

// 商品列表
export function bankList(query) {
  return fetch({
    url: '/api/member/bankList',
    method: 'post',
    data: query
  })
}
// 添加商品
export function addGoods(query) {
  return fetch({
    url: '/api/goods/add',
    method: 'post',
    data: query
  })
}
// 编辑商品
export function updateBankList(query) {
  return fetch({
    url: '/api/member/updateBankList',
    method: 'post',
    data: query
  })
}
// 删除商品
export function deleteGoods(query) {
  return fetch({
    url: '/api/goods/delete',
    method: 'post',
    data: query
  })
}



// 客服列表
export function customerList(query) {
  return fetch({
    url: '/api/customer/list',
    method: 'post',
    data: query
  })
}
// 添加客服
export function addCustomer(query) {
  return fetch({
    url: '/api/customer/add',
    method: 'post',
    data: query
  })
}
// 编辑客服
export function updateCustomer(query) {
  return fetch({
    url: '/api/customer/update',
    method: 'post',
    data: query
  })
}
// 客服禁用
export function updateStatus(query) {
  return fetch({
    url: '/api/customer/updateStatus',
    method: 'post',
    data: query
  })
}
// 代理列表
export function agentList(query) {
  return fetch({
    url: '/api/agent/list',
    method: 'post',
    data: query
  })
}
// 新增代理
export function agentInsert(query) {
  return fetch({
    url: '/api/agent/insert',
    method: 'post',
    data: query
  })
}
// 编辑代理
export function agentUpdate(query) {
  return fetch({
    url: '/api/agent/update',
    method: 'post',
    data: query
  })
}
// 更改密码
export function agentUpdatePwd(query) {
  return fetch({
    url: '/api/agent/updatePwd',
    method: 'post',
    data: query
  })
}
// 启用 禁用
export function agentUpdateStatus(query) {
  return fetch({
    url: '/api/agent/updateStatus',
    method: 'post',
    data: query
  })
}
// 代理下拉列表

export function belongAgentList(query) {
  return fetch({
    url: '/api/agent/belongAgentList',
    method: 'get',
    data: query
  })
}

// 代理删除
export function deleteAgentList(query) {
  return fetch({
    url: '/api/agent/delete',
    method: 'post',
    data: query
  })
}
