import { render, staticRenderFns } from "./card-draggable.vue?vue&type=template&id=15fe5794&scoped=true"
import script from "./card-draggable.vue?vue&type=script&lang=js"
export * from "./card-draggable.vue?vue&type=script&lang=js"
import style0 from "./card-draggable.vue?vue&type=style&index=0&id=15fe5794&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15fe5794",
  null
  
)

export default component.exports