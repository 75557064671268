<template>
  <div class="main-container">
 <el-form style="background: white;padding: 20px 20px 0" size="mini" :inline="true" :model="formInline" class="demo-form-inline">
  <el-form-item label="一级代理">
    <el-select v-model="formInline.region1" placeholder="请选择一级代理">
      <el-option
      v-for="item in primaryAgencyOption"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
    </el-select>
  </el-form-item>
  <el-form-item label="业务员">
    <el-select v-model="formInline.region2" placeholder="请选择业务员">
      <el-option
      v-for="item in salesmanOption"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
    </el-select>
  </el-form-item>
    <el-form-item label="叠加组">
    <el-select v-model="formInline.region3" placeholder="请选择叠加组">
   <el-option
      v-for="item in groupOption"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
    </el-select>
  </el-form-item>
    <el-form-item label="排序方式">
    <el-select v-model="formInline.region4" placeholder="请选择排序方式">
         <el-option
      v-for="item in sortModelOption"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
    </el-select>
  </el-form-item>
   <el-form-item label="用户名称">
    <el-input v-model="formInline.region5" placeholder="请输入用户名称"></el-input>
  </el-form-item>
  <el-form-item label="手机号码">
    <el-input v-model="formInline.region6" placeholder="请输入手机号码"></el-input>
  </el-form-item>
  <el-form-item label="邀请码">
    <el-input v-model="formInline.region7" placeholder="请输入邀请码"></el-input>
  </el-form-item>
  <el-form-item label="注册时间">
    <el-input v-model="formInline.region8" placeholder="请输入注册时间"></el-input>
  </el-form-item>
  <el-form-item>
    <el-button type="primary" @click="onSubmit">搜索</el-button>
  </el-form-item>
</el-form>

    <TableBody ref="tableBody">
      <template>
        <el-table
          ref="table"
          v-loading="tableLoading"
          :data="dataList"
          :header-cell-style="tableConfig.headerCellStyle"
          :size="tableConfig.size"
          :stripe="tableConfig.stripe"
          :border="false"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="45"
          />
          <el-table-column
            align="center"
            label="UID"
            width="80"
            prop="uid"
          >
          </el-table-column>
          <el-table-column
            align="center"
            label="注册时间"
            prop="registerTime"
          />

          <el-table-column
            align="center"
            label="一级代理"
            prop=""
          >

          </el-table-column>
          <el-table-column
            align="center"
            label="业务员"
            prop=""
          />
          <el-table-column
            align="center"
            label="账号"
            prop="account"
            width="160px"
          />
          <el-table-column
            align="center"
            label="国家"
            prop="area"
            width="130px"
          />
          <el-table-column
            align="center"
            label="抢单开关"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.grabSwitch"
                :active-value="1"
                :inactive-value="0"
              />
            </template>
          </el-table-column>

          <el-table-column
            align="center"
            label="用户名"
            prop="username"
            width="130px"
          />
          <el-table-column
            align="center"
            label="赠金"
            prop="giveCash"
            width="130px"
          />
          <el-table-column
            align="center"
            label="账户余额"
            prop="balance"
            width="130px"
          />
          <el-table-column
            align="center"
            label="佣金"
            prop="commission"
            width="130px"
          />
          <el-table-column
            align="center"
            label="下级佣金"
            prop="levelCommission"
            width="130px"
          />
          <el-table-column
            align="center"
            label="累计充值金额"
            prop="topUpTotalBalance"
            width="130px"
          />
           <el-table-column
            align="center"
            label="累计充值次数"
            prop="topUpTotalCount"
            width="130px"
          />
          <el-table-column
            align="center"
            label="累计提现金额"
            prop="withdrawalTotalBalance"
            width="130px"
          />
           <el-table-column
            align="center"
            label="累计提现次数"
            prop="withdrawalTotalCount"
            width="130px"
          />



          <el-table-column
            align="center"
            label="冻结金额"
            prop="freezeAmount"
            width="130px"
          />
          <el-table-column
            align="center"
            label="上级用户"
            prop="upLevelUserName"
            width="130px"
          />
           <el-table-column
            align="center"
            label="邀请码"
            prop="inviteCode"
            width="130px"
          />


          <el-table-column
            align="center"
            label="最后登录ip"
            prop="ip"
            width="130px"
          />
          <el-table-column
            align="center"
            label="最后登录时间"
            prop="loginTime"
            width="130px"
          />
           <el-table-column
            align="center"
            label="备注"
            prop="comment"
            width="130px"
          />
          <el-table-column
            align="center"
            width="150"
            label="操作"
            fixed="right"

          >
            <template slot-scope="scope">
                <!-- <el-button size="mini" type="primary" style="width: 50px">做单</el-button> -->


                <el-button @click="edit(scope.row)" size="mini" type="success">编辑</el-button>
                <!-- <el-button size="mini" type="danger">禁用</el-button> -->
                <el-button size="mini" type="danger">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

<el-dialog
  title="提示"
  :visible.sync="dialogVisible"
  width="30%"
  :before-close="handleClose">
  <el-form size="mini" style="width: 300px"  ref="form" :model="form" label-width="80px">
  <el-form-item label="账户余额:">
    <el-input v-model="form.balance"></el-input>
  </el-form-item>

  <el-form-item label="备注:">
    <el-input v-model="form.comment"></el-input>
  </el-form-item>
  <el-form-item label="禁用开关:">
   <el-switch
                v-model="form.status"
                :active-value="0"
                :inactive-value="-1"
              />
  </el-form-item>
</el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirmEdit(form)">确 定</el-button>
  </span>
</el-dialog>

      </template>


          <!-- 分页区域 -->
    <div class="serviceManage-page">
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="formInline.page"
      :page-size="formInline.size"
      :page-sizes="[10, 20, 30, 40]"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      >
      </el-pagination>
    </div>


    </TableBody>
  </div>
</template>

<script>
import {memberList, memberEdit} from '../../api/index'
import TableMixin, { PageModelMixin } from '@/mixins/TableMixin'
import { GetDataMixin, DeleteItemsMixin } from '@/mixins/ActionMixin'
export default {
  name: 'Table',
  mixins: [TableMixin, PageModelMixin, GetDataMixin, DeleteItemsMixin],
  data() {
    return {
      dialogVisible: false,
      form: {},
       formInline: {
        page: 1,
        size: 10
        },
        total: '',
        primaryAgencyOption: [{
          label: '全部',
          value: ''
        }],
        salesmanOption: [{
          label: '全部',
          value: ''
        }],
        groupOption: [{
          label: '全部',
          value: ''
        }],
        sortModelOption: [{
          label: '充值金额倒序',
          value: ''
        },
        {
          label: '充值次序倒序',
          value: ''
        },
        {
          label: '提现金额倒序',
          value: ''
        },
        {
          label: '提现次数倒序',
          value: ''
        }],
    }
  },
  mounted() {
    this.getMemberList()

  },
  methods: {
    edit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogVisible = true
    },

    confirmEdit() {

       const data = {
        account: this.form.account.substring(2),
        balance: Number(this.form.balance),
        status: this.form.status,
        comment: this.form.comment
       }
       memberEdit(data).then(res => {
        if (res.data.status == 0) {
          this.dialogVisible = false
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.getMemberList()
        }
       })
    },

    // 获取会员列表
    getMemberList() {
          memberList({page: this.formInline.page, size: this.formInline.size}).then(res => {
            this.dataList = res.data.result
            this.total =res.data.total

          })
    },
    handleSizeChange(size) {
      this.formInline.size = size
      this.formInline.page = 1
      this.getMemberList()
    },
    handleCurrentChange(page) {
      this.formInline.page = page
      this.getMemberList()
    }
  }

}
</script>

<style lang="scss" scoped>

  .serviceManage-page {
    height: 48.5px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-left: 24px;
  }


.avatar-container {
  position: relative;
  width: 30px;
  margin: 0 auto;
  vertical-align: middle;
  .avatar {
    width: 100%;
    border-radius: 50%;
  }
  .avatar-vip {
    border: 2px solid #cece1e;
  }
  .vip {
    position: absolute;
    top: 0;
    right: -9px;
    width: 15px;
    transform: rotate(60deg);
  }
}
.gender-container {
  .gender-icon {
    width: 20px;
  }
}
</style>
