<template>
  <el-card>
    下一级页面
  </el-card>
</template>

<script>
export default {
  name: 'NextChild'
}
</script>

<style>
</style>
